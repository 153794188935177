// extracted by mini-css-extract-plugin
export var heroHeaderLeft = "m_qh d_gv d_cs";
export var heroHeaderCenter = "m_gw d_gw d_cs d_dw";
export var heroHeaderRight = "m_gx d_gx d_cs d_dx";
export var heroParagraphLeft = "m_qj d_gr d_cw";
export var heroParagraphCenter = "m_gs d_gs d_cw d_dw";
export var heroParagraphRight = "m_gt d_gt d_cw d_dx";
export var heroBtnWrapperLeft = "m_qk d_d2 d_d1 d_w d_bz d_bG";
export var heroBtnWrapperCenter = "m_ql d_d3 d_d1 d_w d_bz d_bD";
export var heroBtnWrapperRight = "m_qm d_d4 d_d1 d_w d_bz d_bH";
export var overlayBtnWrapper = "m_qn d_gq d_0 d_7 d_8 d_9 d_bn d_cc";
export var design4 = "m_qp d_gp d_0 d_7 d_8 d_bn";
export var heroExceptionSmall = "m_qq p_qq";
export var heroExceptionNormal = "m_qr p_qr";
export var heroExceptionLarge = "m_qs p_qs";
export var Title1Small = "m_qt p_qt p_qC p_qD";
export var Title1Normal = "m_qv p_qv p_qC p_qF";
export var Title1Large = "m_qw p_qw p_qC p_qG";
export var BodySmall = "m_qx p_qx p_qC p_qW";
export var BodyNormal = "m_qy p_qy p_qC p_qX";
export var BodyLarge = "m_qz p_qz p_qC p_qY";